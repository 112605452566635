import React, { FunctionComponent } from 'react';

// Contexts
import { useViewDetected } from '../../Utils/ViewDetected';

interface PlaceholderViewProps {
    mobileHeight: number;
    tabletHeight: number;
    desktopHeight: number;
}

const PlaceholderView: FunctionComponent<PlaceholderViewProps> = ({ mobileHeight, tabletHeight, desktopHeight }) => {
  const { deviceType } = useViewDetected();

  const getHeight = (): string => {
    switch (deviceType) {
      case 'desktop': {
        return `${desktopHeight}px`;
      }
      case 'tablet': {
        return `${tabletHeight}px`;
      }
      case 'mobile': {
        return `${mobileHeight}px`;
      }
      default: {
        return '0px';
      }
    }
  };

  return <div style={{ width: '100%', height: getHeight() }} />;
};

export default PlaceholderView;
