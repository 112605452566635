/* eslint-disable consistent-return */
/* eslint-disable indent */
import Axios from 'axios'
// import moment from 'moment'

import isNull from 'lodash/isNull'
import merge from 'lodash/merge'
import size from 'lodash/size'
// import { popup } from '../../../subject'

const _ = {
    isNull, merge, size,
}

class MeAPI {
    config: any;
    constructor(config = {
        url: '',
    }) {
        this.config = config
    }

    async Post(pathUrl: string, payload: string, headers = {}) {
        try {
            const body = payload
            const url = this.config.url + pathUrl

            const response = await Axios.post(url, body, {
                headers: _.merge(headers),
                timeout: 20000,
            })

            console.log('----ressponse', response)
            if (response.status === 200) {
                return {
                    code: 1000,
                    data: { ...response.data?.data },
                    errors: [
                        ...(response.data?.errors || []),
                    ],
                }
            }
            return {
                code: -2,
                data: { message: response.data?.errors[0].message || {} },
            }
        } catch (error) {
            return {
                code: -2,
                data: { message: error.message },
            }
        }
    }
}

function handleGraphQLResponse(response: any) {
    if (response.code === 1000) { // succeeded
        if (response?.errors && _.size(response?.errors) > 0) {
            const { errors } = response
            const error = errors[0]
            // if (error.extensions?.code === 401) {
            //   // do logout
            // }
            if (error.extensions?.code === 401) {
                // popup.next({ message: error.message ?? 'Thông tin xác thực không hợp lệ', isVisible: true })
            } else if (error.extensions?.code === 400) {
                // popup.next({ message: error.message ?? 'Có lỗi từ máy chủ hệ thống', isVisible: true })
            } else {
                // popup.next({ message: error.message ?? 'Có lỗi từ máy chủ hệ thống', isVisible: true })
            }
        }
    } else { // errors
        console.log('error', response?.data?.message ?? 'Có lỗi xảy ra khi kết nối hệ thống!')
        // popup.next({ isVisible: true, message: response?.data?.message ?? 'Có lỗi xảy ra khi kết nối hệ thống!' })
        // show message global popup
    }

    return response
}

export const callApi = async ({
    domain, method, pathUrl, body,
}) => {
    // console.log('[REQUEST]', moment().format('HH:mm:ss'), method.toUpperCase(), domain + pathUrl, body)

    const meAPI = new MeAPI({
        url: domain,
    })

    const headers = {}
    // if (isHeaderSecure !== '') {
    //   headers = {
    //     ...headers,
    //     security: isHeaderSecure
    //   }
    //   // headers.security = isHeaderSecure
    // }

    let response: object
    if (method.toUpperCase() === 'POST') {
        response = await meAPI.Post(pathUrl, body, headers)
    }
    console.log('=====response', response)
    // console.log('[RESPONSE]', moment().format('HH:mm:ss'), method.toUpperCase(), domain + pathUrl, body, response)

    return handleGraphQLResponse(response)
}
