/* eslint-disable no-undef */
import URLRedirect from '@Config/urlRedirect';
import React, { FunctionComponent, useEffect, useState } from 'react'

interface SupportButtonProps {
  urlSupport?: string;
}

export const SupportButton: FunctionComponent<SupportButtonProps> = () => {
  const [url, setUrl] = useState('')
  useEffect(() => {
    setUrl(URLRedirect().support)
  }, [])

  return (
    <a target="_blank" href={url} rel="noreferrer">
      <div
        style={{
          objectFit: 'contain',
          width: 80,
          height: 80,
          bottom: 20,
          right: 20,
          zIndex: 99,
          cursor: 'pointer'
        }}
        className="position-fixed"
      >
        <img src={'/assets/default/buttonSupport.png'} alt="" className="img-fluid" />
      </div>
    </a>
  )
}
