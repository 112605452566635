// interface Api {
//     API_BASE_HOST: string;
//     WSS_BASE_HOST: string;
//     API_UPLOAD_HOST: string;
//     API_EDOCTOR_HOST: string;
// }

// const DEVELOPMENT: Api = {
//   API_BASE_HOST: 'https://virtual-clinic.api.e-doctor.dev',
//   WSS_BASE_HOST: 'wss://virtual-clinic.api.e-doctor.dev',
//   API_UPLOAD_HOST: 'https://upload.e-doctor.dev',
//   API_EDOCTOR_HOST: 'https://doctor.api.e-doctor.dev/v1',
// };

// const STAGING: Api = {
//   API_BASE_HOST: 'https://virtual-clinic.api.edoctor-demo.com',
//   WSS_BASE_HOST: 'wss://virtual-clinic.api.edoctor-demo.com',
//   API_UPLOAD_HOST: 'https://upload.edoctor-demo.com',
//   API_EDOCTOR_HOST: 'https://doctor.api.e-doctor.dev/v1',
// };

// const PRODUCTION: Api = {
//   API_BASE_HOST: 'https://virtual-clinic.api.edoctor.io',
//   WSS_BASE_HOST: 'wss://virtual-clinic.api.edoctor.io',
//   API_UPLOAD_HOST: 'https://upload.edoctor.io',
//   API_EDOCTOR_HOST: 'https://doctor.api.edoctor.io/v1',
// };

// export default ((nodeEnv: string) => {
//   switch (nodeEnv) {
//     case 'production':
//       return PRODUCTION;
//     case 'develop':
//       return DEVELOPMENT;
//     case 'staging':
//       return STAGING;
//     default:
//       return PRODUCTION;
//   }
// })(process.env.RUN_ENV);

const ENV = {
  development: {
    API_BASE_HOST: 'https://virtual-clinic.api.e-doctor.dev',
    WSS_BASE_HOST: 'wss://virtual-clinic.api.e-doctor.dev',
    API_UPLOAD_HOST: 'https://upload.e-doctor.dev',
    API_EDOCTOR_HOST: 'https://doctor.api.e-doctor.dev/v1',
    IMAGE_PREFIX_SHOW: 'https://staff.e-doctor.dev/_upload',
  },
  beta: {
    API_BASE_HOST: 'https://virtual-clinic.api.edoctor-demo.com',
    WSS_BASE_HOST: 'wss://virtual-clinic.api.edoctor-demo.com',
    API_UPLOAD_HOST: 'https://upload.edoctor-demo.com',
    API_EDOCTOR_HOST: 'https://doctor.api.e-doctor.dev/v1',
    IMAGE_PREFIX_SHOW: 'https://staff.e-doctor.dev/_upload',
  },
  production: {
    API_BASE_HOST: 'https://virtual-clinic.api.edoctor.io',
    WSS_BASE_HOST: 'wss://virtual-clinic.api.edoctor.io',
    API_UPLOAD_HOST: 'https://upload.edoctor.io',
    API_EDOCTOR_HOST: 'https://doctor.api.edoctor.io/v1',
    IMAGE_PREFIX_SHOW: 'https://user.edoctor.io/_upload',
  },
}

export default function api() {
  const currentDomain = String(window?.location?.hostname).toLowerCase()
  if (/edoctor\.io$/.test(currentDomain)) {
    return ENV.production
  }
  if (/edoctor-demo\.com$/.test(currentDomain)) {
    return ENV.beta
  }
  return ENV.development
}
